import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { UtilsService } from '../services/utils.service';

// Directive to lazy load images
// Usage: <img [appLazyLoad]="'https://example.com/image.jpg'" />
// Load image only when it is in the viewport
@Directive({
  selector: '[appLazyLoad]',
})
export class LazyLoadDirective implements OnInit {
  @Input() appLazyLoad: string = '';

  constructor(private el: ElementRef, private utils: UtilsService) {}

  ngOnInit(): void {
    let elem = this.el.nativeElement;
    const elemType = elem.nodeName.toLowerCase();
    if (this.utils.isThisBrowser()) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (elemType === 'video') {
              const source = elem.querySelector('source');
              if (source) {
                source.src = this.appLazyLoad;
                elem.load(); // Reload the video with the new source
              }
            } else {
              elem.src = this.appLazyLoad;
            }
            observer.unobserve(elem);
          }
        });
      });

      observer.observe(elem);
    }
  }
}
